/**
 * card-flip
 */

// Module styles
import './_card-flip.scss';

// Module template
import './_card-flip.twig';

export const name = 'card-flip';

export const defaults = {
  dummyClass: 'js-card-flip-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */

(function ($, $context) {
  // Find our component within the DOM
  const $cardFlip = $('.card-front', $context);
  const $cardRotate = $('.card-back', $context);
  $cardFlip.on('click', function () {
    $(this).addClass('card-flip__move');
    $(this).siblings().addClass('card-flip__rotate');
  });
  $cardRotate.on('click', function () {
    $(this).removeClass('card-flip__rotate');
    $(this).siblings().removeClass('card-flip__move');
  });
})(jQuery);
